import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import tmdbApi from '../../api/tmdbApi';
import apiConfig from '../../api/apiConfig';

const CastList = (props) => {
  const { category } = useParams();
  const adRef = useRef(null);
  const [casts, setCasts] = useState([]);

  useEffect(() => {
    const loadAd = () => {
      if (adRef.current) {
        adRef.current.innerHTML = "";
        
        const adScript = document.createElement("script");
        adScript.type = "text/javascript";
        adScript.innerHTML = `
        atOptions = {
		'key' : 'cb252dd2897dc9d3018b54d7656ed797',
		'format' : 'iframe',
		'height' : 600,
		'width' : 160,
		'params' : {}
	};
        `;
        adRef.current.appendChild(adScript);

        const adScriptSrc = document.createElement("script");
        adScriptSrc.type = "text/javascript";
        adScriptSrc.src = "//comfortfluffyflabbergasted.com/cb252dd2897dc9d3018b54d7656ed797/invoke.js";
        adRef.current.appendChild(adScriptSrc);
      }
    };

    loadAd();

    return () => {
      if (adRef.current) {
        adRef.current.innerHTML = ""; // Clean up on unmount
      }
    };
  }, []);

  useEffect(() => {
    const getCredits = async () => {
      const res = await tmdbApi.credits(category, props.id);
      setCasts(res.cast.slice(0, 5)); // Fetch only the first 5 cast members
    };
    getCredits();
  }, [category, props.id]);

  return (
    <div className="casts">
      {casts.map((item, i) => (
        <div key={i} className="casts__item">
          <div
            className="casts__item__img"
            style={{ backgroundImage: `url(${apiConfig.w500Image(item.profile_path)})` }}
          ></div>
          <p className="casts__item__name">{item.name}</p>
        </div>
      ))}
      
      {/* Insert ad as the 6th item */}
      {/* <div className="casts__item" ref={adRef} style={{ height: "20px", overflow:"scroll" }}></div> */}
  

    </div>
  );
};

export default CastList;
