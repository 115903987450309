import React, { useEffect, useRef, useState } from "react";
import "../player/player.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import tmdbApi from "../../api/tmdbApi";
import apiConfig from "../../api/apiConfig";
import { useNavigate } from "react-router-dom";
import MovieList from "../movie-list/MovieList";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

import { ref, get, update } from "firebase/database";
import { auth, db } from "../../firebase";
import ReactGA from "react-ga4";
import VideoList from "../../pages/detail/VideoList";
import DetailPagePlayer from "./DetailPagePlayer";
import CommentsSection from "./CommentsSection";

const Player = () => {
  const { category, id } = useParams();
  const [item, setItem] = useState(null);
  const [seasonsDetails, setSeasonsDetails] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState(1); // Default to season 1
  const [selectedEpisode, setSelectedEpisode] = useState(1); // Default to episode 1
  const [server, setServer] = useState(1);
  const [isFavorite, setIsFavorite] = useState(false); // State for bookmarked status
  const [ShowFavouriteDialog, setShowFavouriteDialog] = useState(false);
  const navigate = useNavigate();
  const userEmail = localStorage.getItem("email");
  const [loggedIn, setLoggedIn] = useState(userEmail != null);
  const [loading, setLoading] = useState(true);
  const [viewCount, setViewCount] = useState(0);
  const adRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [torrentLinks, setTorrentLinks] = useState([]);
  const [noFilesAvailable, setNoFilesAvailable] = useState(false); // New state for no files available

  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  // Check if the current item is in favorites when component mounts

  useEffect(() => {
    const getDetail = async () => {
      const response = await tmdbApi.detail(category, id, { params: {} });
      setItem(response);

      if (category === "tv") {
        const seasonsPromises = response.seasons.map((season) =>
          tmdbApi.season(category, id, season.season_number, { params: {} })
        );
        const seasonsData = await Promise.all(seasonsPromises);
        setSeasonsDetails(seasonsData);

        // Check for selected season and episode in URL parameters
        const urlSeason = parseInt(
          new URLSearchParams(window.location.search).get("season")
        );
        const urlEpisode = parseInt(
          new URLSearchParams(window.location.search).get("episode")
        );

        // Automatically select season and episode based on URL or default
        if (seasonsData.length > 0) {
          const seasonToSelect = urlSeason || 1;
          setSelectedSeason(seasonToSelect);
          const selectedSeasonData = seasonsData.find(
            (season) => season.season_number === seasonToSelect
          );
          if (selectedSeasonData && selectedSeasonData.episodes.length > 0) {
            const episodeToSelect =
              urlEpisode || selectedSeasonData.episodes[0].episode_number;
            setSelectedEpisode(episodeToSelect);
          }
        }
      }

      window.scrollTo(0, 0);
    };

    getDetail();
  }, [category, id]);

  const handleSeasonChange = (seasonNumber) => {
    setSelectedSeason(seasonNumber);
    const selectedSeasonData = seasonsDetails.find(
      (season) => season.season_number === seasonNumber
    );
    if (selectedSeasonData && selectedSeasonData.episodes.length > 0) {
      setSelectedEpisode(selectedSeasonData.episodes[0].episode_number); // Auto-select first episode
    } else {
      setSelectedEpisode(null); // No episodes available
    }
  };

  const handleEpisodeClick = (episodeNumber) => {
    setSelectedEpisode(episodeNumber);
  };

  const handleServerChange = (serverNumber) => {
    setServer(serverNumber);
  };

  const handleIframeError = () => {
    setLoading(false);
  };

  const renderSeasonsDropdown = () => {
    return (
      <select
        className="season-dropdown"
        value={selectedSeason || ""}
        onChange={(e) => handleSeasonChange(parseInt(e.target.value))}
      >
        {seasonsDetails
          .filter((season) => season.season_number !== 0)
          .map((season) => (
            <option key={season.season_number} value={season.season_number}>
              Season {season.season_number}
            </option>
          ))}
      </select>
    );
  };

  const renderEpisodesList = () => {
    if (!selectedSeason) return null;

    const selectedSeasonData = seasonsDetails.find(
      (season) => season.season_number === selectedSeason
    );

    if (!selectedSeasonData) return null;

    return (
      <div>
        <ul className="ep-dropdown-ul">
          {selectedSeasonData.episodes.map((episode) => (
            <li key={episode.episode_number} className="ep-dropdown-li">
              <button
                onClick={() => handleEpisodeClick(episode.episode_number)}
                className={
                  selectedEpisode === episode.episode_number
                    ? "active-episode" // Apply the "active-episode" class for the selected episode
                    : ""
                }
              >
                Ep {episode.episode_number}: {episode.name}
              </button>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const renderPlayer = () => {
    ReactGA.event({
      category: "User",
      action: "Watch" + item.title || item.name,
      label: "User visited Watch Page",
      value: 99,
      nonInteraction: true,
      transport: "xhr",
    });
    if (!selectedEpisode && category === "tv") return null;

    let playerSrc = `https://vidsrc.pro/embed/tv/${id}/${selectedSeason}/${selectedEpisode}`;
    if (server === 2) {
      playerSrc = `https://vidsrc.to/embed/${category}/${id}`;
    } else if (server === 3) {
      playerSrc = `https:/english.vidsrc.nl/embed/${category}/${id}/${selectedSeason}/${selectedEpisode}`;
    }

    if (category === "movie") {
      playerSrc = `https://vidsrc.pro/embed/movie/${id}`;
      if (server === 2) {
        playerSrc = `https://vidsrc.to/embed/${category}/${id}`;
      } else if (server === 3) {
        playerSrc = `https:/english.vidsrc.nl/embed/${category}/${id}`;
      }
    }

    return (
      <div className="watching_player">
        <div className="watching_player-area">
          {isLoading && (
            <div className="spinner-container" style={{ height: "20px" }}>
              Loading...
            </div>
          )}
          <div id="mask-player" style={{ paddingBottom: "56.25%" }}></div>
          <div id="watch-player" style={{ background: "red" }}>
            <iframe
              allowFullScreen={true}
              mozallowfullscreen="true"
              src={playerSrc}
              webkitallowfullscreen="true"
              width="100%"
              onLoad={handleIframeLoad}
              onError={handleIframeError}
            ></iframe>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
   

    const loadAd = () => {
      if (adRef.current) {
        adRef.current.innerHTML = "";

        const adScript = document.createElement("script");
        adScript.type = "text/javascript";
        adScript.innerHTML = `
          atOptions = {
            'key' : '9ff14816438fafa02791cf3fcd9ee660',
            'format' : 'iframe',
            'height' : 90,
            'width' : 728,
            'params' : {}
          };
        `;
        adRef.current.appendChild(adScript);

        const adScriptSrc = document.createElement("script");
        adScriptSrc.type = "text/javascript";
        adScriptSrc.src =
          "//www.topcreativeformat.com/9ff14816438fafa02791cf3fcd9ee660/invoke.js";
        adRef.current.appendChild(adScriptSrc);
      }
    };

    loadAd();

    return () => {
      if (adRef.current) {
        adRef.current.innerHTML = ""; // Clean up on unmount
      }
    };
  }, [item, category]);

  if (!item) {
    return (
      <div>
        {" "}
        <div className="spinner-container">
          <div className="pulsing-logo"></div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Watch - {item.title || item.name}</title>
        <meta name="title" content={item.title || item.name} />
        <link
          rel="canonical"
          href={`https://www.filmtv.site/watch/${category}/${id}`}
        />
        <meta
          name="description"
          content={
            item.overview || "Watch the latest movies and TV shows on Film TV."
          }
        />
        <meta
          name="keywords"
          content={`
    Watch ${item?.title || item?.name} Online Free,
    ${item?.title || item?.name} Online Free,
    Where to watch ${item?.title || item?.name},

  `}
        />

        <meta
          itemProp="image"
          property="og:image"
          content={apiConfig.originalImage(
            item.poster_path || item.backdrop_path
          )}
        />

        <meta property="og:type" content="website" />
        <meta property="og:image:width" content="300" />
        <meta property="og:image:height" content="300" />
        <meta property="og:image:type" content="image/png" />
        <meta
          property="og:url"
          content={`https://www.filmtv.site/watch/${category}/${id}`}
        />
      </Helmet>
      <div
        id="wrapper"
        style={{
          textAlign: "center",
          marginTop: "120px",
        }}
      ></div>

      <div className="container">
        <div className="prebreadcrumb">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/" className="Home">
                  Home
                </a>
              </li>
              <li className="breadcrumb-item">
                <a href={`/${category}`} title="Movie" className="Cate">
                  {category}
                </a>
              </li>
              <li className="breadcrumb-item">
                <a href={`/${category}/${id}`} title={item.title || item.name}>
                  {item.title || item.name}
                </a>
              </li>
            </ol>
          </nav>
        </div>
        <div
          className="alert mb-3"
          style={{
            background: "#ffaa00",
            color: "#111",
            fontSize: "16px",
            fontWeight: 600,
          }}
        >
          If you get any error message when trying to stream, please Refresh the
          page or switch to another streaming server.
        </div>
        <div
          ref={adRef}
          className="ad-container section mb-3"
          style={{ display: "flex", justifyContent: "center" }}
        ></div>

        {category === "tv" && (
          <div className="player-container">
            {renderPlayer()}
            {server === 1 && (
              <div className="dropdown-container">
                {renderSeasonsDropdown()}
                {renderEpisodesList()}
              </div>
            )}
            {server === 3 && (
              <div className="dropdown-container">
                {renderSeasonsDropdown()}
                {renderEpisodesList()}
              </div>
            )}
          </div>
        )}

        {category === "movie" && (
          <div className="player-container-movie">{renderPlayer()}</div>
        )}

        <div className="server-buttons">
          <button onClick={() => handleServerChange(1)}>Server 1</button>
          <button onClick={() => handleServerChange(2)}>Server 2</button>
          <button onClick={() => handleServerChange(3)}>Server 3</button>
        </div>

        <div
          ref={adRef}
          className="ad-container section mb-3 mt-3"
          style={{ display: "flex", justifyContent: "center" }}
        ></div>


        <DetailPagePlayer />

      
      </div>
    </div>
  );
};

export default Player;
