import React, { useState, useEffect } from "react";
import "./tv-banner.scss"; // Keep your SCSS styling
import { Link } from "react-router-dom";
import tmdbApi, { category, tvType } from "../../api/tmdbApi";
import apiConfig from "../../api/apiConfig";
import placeholderImage from "../../assets/placeholder.jpg";

const TvBanner = ({ tvId }) => {
  const [tvShow, setTvShow] = useState(null);
  const [bg, setBg] = useState(placeholderImage);
  const [description, setDescription] = useState("");
  const [tvShowItems, setTvShowItems] = useState([]);

  useEffect(() => {
    const getTvs = async () => {
      const params = { page: 1 };
      try {
        const response = await tmdbApi.getTvList(tvType.top_rated, {
          params,
        });
        const tvShowsWithDetails = await Promise.all(
          response.results.slice(0, 5).map(async (tvShow) => {
            const details = await tmdbApi.detail(category.tv, tvShow.id, {
              params: {},
            });
            return {
              ...tvShow,
              runtime: details.runtime,
              genres: details.genres || [], // Handle missing genres gracefully
            };
          })
        );
        setTvShowItems(tvShowsWithDetails);

        // If tvId is provided, find the TV show from the list
        if (tvId) {
          const selectedTvShow = tvShowsWithDetails.find(
            (item) => item.id === tvId
          );
          setTvShow(selectedTvShow || tvShowsWithDetails[0]); // Default to first TV show if no match
        } else {
          setTvShow(tvShowsWithDetails[0]); // Default to first TV show if no tvId is passed
        }
      } catch (error) {
        console.log("Error fetching TV shows:", error);
      }
    };
    getTvs();
  }, [tvId]); // Depend on tvId to update when it's passed as a prop

  // Set background image
  useEffect(() => {
    if (tvShow) {
      const actualImage = apiConfig.w500Image(
        tvShow.backdrop_path || tvShow.poster_path
      );
      const img = new Image();
      img.src = actualImage;
      img.onload = () => setBg(actualImage);
      img.onerror = () => setBg(placeholderImage);

      // Set description with dynamic truncation
      const updateDescription = () => {
        if (window.innerWidth < 768) {
          setDescription(tvShow.overview.slice(0, 150) + "...");
        } else {
          setDescription(tvShow.overview);
        }
      };

      // Initialize description and set event listener for resize
      updateDescription();
      window.addEventListener("resize", updateDescription);

      return () => window.removeEventListener("resize", updateDescription);
    }
  }, [tvShow]);

  if (!tvShow) return null; // Return null if TV show data is not yet loaded

  return (
    <>
      <div
        className="banner-image"
        style={{
          backgroundImage: `url(https://wallpapercave.com/wp/3tJxwHy.jpg)`,
        }}
      >
        <div className="banner-content">
          <div className="details-container">
            <h1 className="title">{tvShow.name}</h1> {/* TV show name */}
            <p className="description">{description}</p>
            <Link
              to={`/${category.tv}/${tvShow.id}`} // Updated category from movie to tv
              className="watch-now-button"
            >
              Watch Now
            </Link>
          </div>
          <img
            src={apiConfig.w500Image(tvShow.poster_path)}
            alt={tvShow.name} // TV show name
            className="poster"
          />
        </div>
      </div>
    </>
  );
};

export default TvBanner;
