import React, { useState, useEffect } from "react";
import "./movie-banner.scss"; // Keep your SCSS styling
import { Link } from "react-router-dom";
import tmdbApi, { category, movieType } from "../../api/tmdbApi";
import apiConfig from "../../api/apiConfig";
import placeholderImage from "../../assets/placeholder.jpg";

const MovieBanner = ({ movieId }) => {
  const [movie, setMovie] = useState(null);
  const [bg, setBg] = useState(placeholderImage);
  const [description, setDescription] = useState("");
  const [movieItems, setMovieItems] = useState([]);

  useEffect(() => {
    const getMovies = async () => {
      const params = { page: 1 };
      try {
        const response = await tmdbApi.getMoviesList(movieType.top_rated, {
          params,
        });
        const moviesWithDetails = await Promise.all(
          response.results.slice(0, 5).map(async (movie) => {
            const details = await tmdbApi.detail(category.movie, movie.id, {
              params: {},
            });
            return {
              ...movie,
              runtime: details.runtime,
              genres: details.genres || [], // Handle missing genres gracefully
            };
          })
        );
        setMovieItems(moviesWithDetails);

        // If movieId is provided, find the movie from the list
        if (movieId) {
          const selectedMovie = moviesWithDetails.find(
            (item) => item.id === movieId
          );
          setMovie(selectedMovie || moviesWithDetails[0]); // Default to first movie if no match
        } else {
          setMovie(moviesWithDetails[0]); // Default to first movie if no movieId is passed
        }
      } catch (error) {
        console.log("Error fetching movies:", error);
      }
    };
    getMovies();
  }, [movieId]); // Depend on movieId to update when it's passed as a prop

  // Set background image and update description based on screen size
  useEffect(() => {
    if (movie) {
      const actualImage = apiConfig.w500Image(
        movie.backdrop_path || movie.poster_path
      );
      const img = new Image();
      img.src = actualImage;
      img.onload = () => setBg(actualImage);
      img.onerror = () => setBg(placeholderImage);

      // Set description with dynamic truncation
      const updateDescription = () => {
        if (window.innerWidth < 768) {
          setDescription(movie.overview.slice(0, 150) + "...");
        } else {
          setDescription(movie.overview);
        }
      };

      // Initialize description and set event listener for resize
      updateDescription();
      window.addEventListener("resize", updateDescription);

      return () => window.removeEventListener("resize", updateDescription);
    }
  }, [movie]);

  if (!movie) return null; // Return null if movie data is not yet loaded

  return (
    <>
      <div
        className="banner-image"
        style={{
          backgroundImage: `url(https://wallpaper.forfun.com/fetch/47/47a2bb0ec66330a71b066d3ddba80212.jpeg?w=1470&r=0.5625)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="banner-content">
          <img
            src={apiConfig.w500Image(movie.poster_path)}
            alt={movie.title}
            className="poster"
          />

          <div className="details-container">
            <h1 className="title">{movie.title}</h1>
            <p className="description">{description}</p>
            <Link
              to={`/${category.movie}/${movie.id}`}
              className="watch-now-button"
            >
              Watch Now
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default MovieBanner;
