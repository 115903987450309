import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './movie-list.scss';

import { SwiperSlide, Swiper } from 'swiper/react';

import tmdbApi, { genres } from '../../api/tmdbApi';
import MovieCard from '../movie-card/MovieCard';

const ActionList = props => {
    const [items, setItems] = useState([]);
  
    useEffect(() => {
        const getList = async () => {
          let response = null;
      
            const params = {};
            switch (props.genres) {
              
              case genres.anime:
                response = await tmdbApi.getByGenre('movie', '28', { params });
                break;
             
            }
          
          console.log("Response of Result :", response.results);
          setItems(response.results);
     
        };
        getList();
      }, [props.category, props.genres]);

    return (
        <div className="movie-list">
            <Swiper
                grabCursor={true}
                spaceBetween={10}
                slidesPerView={'auto'}
            >
                {
                    items.map((item, i) => (
                        <SwiperSlide key={i}>
                            <div className='cards'> 
                                <MovieCard item={item} category={props.category}/>
                            </div>
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </div>
    );
}

ActionList.propTypes = {
    category: PropTypes.string.isRequired,
    genre: PropTypes.string.isRequired
}

export default ActionList;
