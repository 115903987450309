import React, { useState, useEffect } from "react";
import { ref, set, get, remove } from "firebase/database";
import { db, auth } from "../../firebase";
import { useParams, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentAlt } from "@fortawesome/free-solid-svg-icons";
import "./comment.css";

const CommentsSection = () => {
  const { category, id } = useParams();
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const emailFromLocalStorage = localStorage.getItem("email");

    if (emailFromLocalStorage) {
      setUser({ email: emailFromLocalStorage });
    } else {
      const unsubscribe = auth.onAuthStateChanged((currentUser) => {
        setUser(currentUser);
      });

      return () => unsubscribe();
    }
  }, []);

  useEffect(() => {
    const fetchComments = async () => {
      const commentsRef = ref(db, `comments/${category}/${id}`);
      const snapshot = await get(commentsRef);
      if (snapshot.exists()) {
        const commentsData = snapshot.val();
        setComments(
          Object.entries(commentsData).map(([commentId, comment]) => ({
            ...comment,
            id: commentId,
          }))
        );
      }
      setLoading(false);
    };

    fetchComments();
  }, [category, id]);

  const handleSubmitComment = async (e) => {
    e.preventDefault();
    if (!newComment.trim()) return;

    const userEmail = user?.email || "Anonymous";
    const commentId = Date.now().toString(); // Ensures ID consistency
    const newCommentData = {
      userEmail,
      comment: newComment,
      timestamp: Date.now(),
    };

    const commentRef = ref(db, `comments/${category}/${id}/${commentId}`);
    await set(commentRef, newCommentData);

    setNewComment("");
    setComments((prevComments) => [
      ...prevComments,
      { ...newCommentData, id: commentId },
    ]);
  };

  const deleteComment = async (commentId) => {
    const commentToDelete = comments.find((comment) => comment.id === commentId);
    if (commentToDelete?.userEmail === user?.email) {
      const commentRef = ref(db, `comments/${category}/${id}/${commentId}`);
      await remove(commentRef);
      setComments((prevComments) =>
        prevComments.filter((comment) => comment.id !== commentId)
      );
    } else {
      alert("You can only delete your own comments.");
    }
  };

  const getCommenterName = (email) => {
    if (!email) return "Anonymous";
    const [name, domain] = email.split("@");
    return `${name.slice(0, 3)}***@${domain}`;
  };

  const getProfileImage = (email) => {
    const initials = email ? email.charAt(0).toUpperCase() : "A";
    return `https://ui-avatars.com/api/?name=${initials}&background=random&color=fff`;
  };

  return (
    <div className="comment-section">
      <h3>
        <FontAwesomeIcon icon={faCommentAlt} /> Comments
      </h3>

      {loading ? (
        <div>Loading comments...</div>
      ) : (
        <div className="comments-list">
          {comments.length > 0 ? (
            comments
              .sort((a, b) => b.timestamp - a.timestamp)
              .map((comment) => (
                <div key={comment.id} className="comment-item">
                  <div className="comment-user">
                    <img
                      src={getProfileImage(comment.userEmail)}
                      alt={getCommenterName(comment.userEmail)}
                      className="comment-avatar"
                    />
                    <div className="commenter-details">
                      <div className="commenterName">
                        <span>{getCommenterName(comment.userEmail)}</span>
                        {user?.email === comment.userEmail && (
                          <button
                            className="delete-comment-btn"
                            onClick={() => deleteComment(comment.id)}
                          >
                            Delete
                          </button>
                        )}
                      </div>
                      <div className="comment-timestamp">
                        {new Date(comment.timestamp).toLocaleString()}
                      </div>
                    </div>
                  </div>
                  <div className="comment-text">{comment.comment}</div>
                </div>
              ))
          ) : (
            <div>No comments yet. Be the first to comment!</div>
          )}
        </div>
      )}

      {user ? (
        <form onSubmit={handleSubmitComment} className="comment-form">
          <textarea
            placeholder="Write a comment..."
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            rows="4"
          />
          <button type="submit" className="submit-comment-btn">
            Post Comment
          </button>
        </form>
      ) : (
        <div className="signin-prompt">
          <p>
            You must <Link to="/signin">Sign In</Link> to post a comment.
          </p>
        </div>
      )}
    </div>
  );
};

export default CommentsSection;
