import React, { useEffect, useState, useCallback, useRef } from "react";
import debounce from "lodash.debounce";
import tmdbApi from "../../api/tmdbApi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./header.scss";
import logo from "../../assets/logo2.png";
import placeholder from "../../assets/placeholder.jpg";

const headerNav = [
  
  { display: <i className="bx bxs-home"></i>, path: "/", tooltip: "Home" },
  { display: <i className="bx bxs-movie"></i>, path: "/movie", tooltip: "Movies" },
  { display: <i className="bx bxs-tv"></i>, path: "/tv", tooltip: "TV Shows" },
  { display: "Genres", path: "#", tooltip: "Genres" },
  { display: "Search", path: "#", tooltip: "Search" },
  { display: <i className="bx bxs-user"></i>, path: "/signin", tooltip: "Profile" },

];

const genres = [
  { id: "action", name: "Action" },
  { id: "adventure", name: "Adventure" },
  { id: "animation", name: "Animation" },
  { id: "comedy", name: "Comedy" },
  { id: "crime", name: "Crime" },
  { id: "documentary", name: "Documentary" },
  { id: "drama", name: "Drama" },
  { id: "family", name: "Family" },
  { id: "fantasy", name: "Fantasy" },
  { id: "history", name: "History" },
  { id: "horror", name: "Horror" },
  { id: "music", name: "Music" },
  { id: "mystery", name: "Mystery" },
  { id: "romance", name: "Romance" },
  { id: "scifi", name: "Science Fiction" },
  { id: "tv-movie", name: "TV Movie" },
  { id: "thriller", name: "Thriller" },
  { id: "war", name: "War" },
  { id: "western", name: "Western" },
];




const Header = () => {
  const { pathname } = useLocation();
  const headerRef = useRef(null);
  const [isGenresOpen, setIsGenresOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); 
  const [searchType, setSearchType] = useState("movie"); 
  const [isSearchOpen, setIsSearchOpen] = useState(false); 
  const userToken = localStorage.getItem("token");
  const userPhoto = localStorage.getItem("picture");
  const [suggestions, setSuggestions] = useState([]);
  const [keyword, setKeyword] = useState("");

  
  const navigate = useNavigate();
  const active = headerNav.findIndex((e) => e.path === pathname);

  useEffect(() => {
    const shrinkHeader = () => {
      if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
        headerRef.current.classList.add("shrink");
      } else {
        headerRef.current.classList.remove("shrink");
      }
    };
    window.addEventListener("scroll", shrinkHeader);
    return () => {
      window.removeEventListener("scroll", shrinkHeader);
    };
  }, []);

  const handleProfileClick = () => {
    if (userToken) {
      navigate("/profile");
    } else {
      navigate("/signin");
    }
  };

  const fetchSuggestions = useCallback(
    debounce(async (query) => {
      if (query.trim().length > 0) {
        const params = { query };

        try {
          const [movieResponse, tvResponse] = await Promise.all([
            tmdbApi.search('movie', { params }),
            tmdbApi.search('tv', { params })
          ]);

          const combinedResults = [
            ...movieResponse.results.map(item => ({ ...item, media_type: 'movie' })),
            ...tvResponse.results.map(item => ({ ...item, media_type: 'tv' }))
          ];

          setSuggestions(combinedResults);
        } catch (error) {
          console.error('Error fetching suggestions:', error);
        }
      } else {
        setSuggestions([]);
      }
    }, 300),
    []
  );


  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);  // Update the search query
    fetchSuggestions(value); // Fetch suggestions
  };
  const handleSearchTypeChange = (e) => {
    setSearchType(e.target.value); // Update search type (movie or tv)
  };
  
  
  const goToSearch = () => {
    if (keyword.trim().length > 0) {
      navigate(`/movie/search/${keyword}`);
      setSuggestions([]);
    }
  };

  useEffect(() => {
    const enterEvent = (e) => {
      e.preventDefault();
      if (e.keyCode === 13) {
        goToSearch();
      }
    };
    document.addEventListener("keyup", enterEvent);
    return () => {
      document.removeEventListener("keyup", enterEvent);
    };
  }, [keyword, goToSearch]);

  const handleGenresClick = () => {
    setIsGenresOpen(true); // Open the modal when genres are clicked
  };

  const handleGenreSelect = (genreId) => {
    setIsGenresOpen(false); // Close the modal after selecting a genre
    navigate(`/genre/${genreId}`);
  };

  const closeModal = () => {
    setIsGenresOpen(false); // Close the modal
  };
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      navigate(`/${searchType}/search/${searchQuery}`);
      setSuggestions([]);  // Clear suggestions after submit
      setSearchQuery("");   // Clear input field
      closeSearchModal();   // Close the search modal
    }
  };
  const openSearchModal = () => {
    setIsSearchOpen(true); // Open the search modal
  };

  const handleSuggestionClick = (suggestion) => {
    setKeyword(suggestion.title);
    setSuggestions([]);  // Clear suggestions
    setSearchQuery("");   // Clear input field
    navigate(
      suggestion.media_type === 'movie' 
        ? `/movie/${suggestion.id}` 
        : `/tv/${suggestion.id}`
    );
    closeSearchModal();   // Close the search modal
  };

  const closeSearchModal = () => {
    setIsSearchOpen(false); // Close the search modal
  };

  return (
    <div ref={headerRef} className="header">
      <div className="header__wrap container">
        <div className="logo">
          <img src={logo} alt="FilmTv logo" />
          <Link to="/">FilmTV</Link>
        </div>

        <ul className="header__nav">
          {headerNav.map((e, i) => (
            <li key={i} className={`${i === active ? "active" : ""}`}>
              {e.display === "Genres" ? (
                <div
                  className="nav-link"
                  onClick={handleGenresClick}
                  style={{ cursor: "pointer" }}
                  title={e.tooltip}
                >
                  <i className="bx bxs-category-alt"></i>
                </div>
              ) : e.display === "Search" ? (
                <div className="search-icon" onClick={openSearchModal} style={{ cursor: "pointer" }} title={e.tooltip}>
                  <i className="bx bx-search"></i>
                </div>
              )   : e.path === "/signin" ? (
                userToken ? (
                  <div className="small">
                    <Link to="/profile" className="nav-link">
                      <img
                        src={userPhoto || "https://icon-library.com/images/avatar-icon-images/avatar-icon-images-4.jpg"}
                        alt="Profile"
                        title={e.tooltip}
                        style={{ width: "25px", borderRadius: "50%", }}
                      />
                    </Link>
                  </div>
                ) : (
                  <div
                    className="small"
                    style={{ cursor: "pointer", background: "none" }}
                    onClick={handleProfileClick}
                  >
                    {e.icon && <i className={`bi-${e.icon}`}></i>} {e.display}
                  </div>
                )
              ) : (
                <Link to={e.path} className="nav-link" title={e.tooltip}>
                  {e.display}
                </Link>
              )}
            </li>
          ))}
        </ul>
      </div>


      {/* Search Modal */}
      {isSearchOpen && (
        <div className="search-modal-overlay" onClick={closeSearchModal}>
          <div className="search-modal-content" onClick={(e) => e.stopPropagation()}>
          <div className="search-header"> <h3>Search</h3>   <button onClick={closeSearchModal} className="search-close-modal">
          <i class='bx bx-x' ></i>
            </button></div>
            <form onSubmit={handleSearchSubmit}>
            <input
              type="text"
              placeholder="Search for movies or TV shows..."
              value={searchQuery}
              onChange={handleInputChange}
            />
            
            {suggestions.length > 0 && (
              <div className="suggestions">
                {suggestions.map((suggestion, index) => (
                  <div
                    key={index}
                    className="suggestion"
                    onClick={() => handleSuggestionClick(suggestion)}
                  >
                    <div className='show-container'> 
                      <img
                        src={
                          suggestion.poster_path
                            ? `https://image.tmdb.org/t/p/w200${suggestion.poster_path}`
                            : placeholder
                        }
                        alt={suggestion.title}
                      />
                      <div className='detail-container'>
                        <div className='suggestion-title'>
                          {suggestion.title || suggestion.original_title || suggestion.original_name}
                        </div>
                        <h6 style={{ color: "yellow" }}>
                          {suggestion.vote_average ? suggestion.vote_average.toFixed(1) : 'N/A'} 
                          <i className='bx bxs-star'></i>
                        </h6>
                        <div className="suggestion-overview">{suggestion.overview}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            
            <div className="search-type-selector">
              <select
                value={searchType}
                onChange={(e) => setSearchType(e.target.value)}
              >
                <option value="movie">Movie</option>
                <option value="tv">TV</option>
              </select>
            </div>
            <button className="search-submit" type="submit">Search</button>
          </form>
            
          </div>
        </div>
      )}

      {/* Modal for Genre Selection */}
      {isGenresOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="genre-header">    <h3>Select Genre</h3>
            <button onClick={closeModal} className="search-close-modal">
          <i class='bx bx-x' ></i>
            </button></div>
           
        
            <ul className="genre-list">
              {genres.map((genre) => (
                <li key={genre.id} onClick={() => handleGenreSelect(genre.id)}>
                  {genre.name}
                </li>
              ))}
            </ul>
           
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
