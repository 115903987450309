import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { sendPasswordResetEmail, signOut as firebaseSignOut } from 'firebase/auth'; // Import signOut from firebase/auth
import { auth } from "../../firebase";
import './profile.css';
import { Helmet } from 'react-helmet';
import logo from '../../assets/preview.png';
import FavouriteList from "../movie-list/FavouriteList";
import { OutlineButton } from "../button/Button";

export default function Profile() {
  const navigate = useNavigate();
  const userEmail = localStorage.getItem("email");
  const userId = localStorage.getItem("userid");
  const userPhotoURL = localStorage.getItem("picture"); // Retrieve photoURL from local storage
  const [hasFavorites, setHasFavorites] = useState(false);

  useEffect(() => {
    const storedFavorites = JSON.parse(localStorage.getItem(userEmail)) || [];
    setHasFavorites(storedFavorites.length > 0);
    if (userEmail == null) {
      navigate('/signin');
    }
  }, [userEmail, navigate]);

  const [showResetDialog, setShowResetDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // Firebase sign out
  const signOut = async () => {
    try {
      await firebaseSignOut(auth); // Sign out from Firebase
      localStorage.removeItem("email");
      localStorage.removeItem("token");
      localStorage.removeItem("picture");
      navigate('/');
    } catch (error) {
      console.error("Error signing out: ", error.message);
    }
  };

  const handleResetPassword = async () => {
    setLoading(true);
    try {
      await sendPasswordResetEmail(auth, userEmail);
      setErrorMessage('');
      alert('Password reset email sent successfully.');
      navigate('/signin');
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
      setShowResetDialog(false);
    }
  };

  return (
    <div className='mt-12'>
      <Helmet>
        <title>Profile</title>
        <meta name="keywords" content="free HD movies, watch movies online, free streaming, HD TV series, online movies, streaming movies, watch TV series, free online movies, HD streaming, free TV shows" />
        <meta name="title" content="Profile" />
        <meta name="description" content="Watch HD Movies and TV series online for free. Stream the latest releases in high quality without any cost." />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://filmtv.site" />
        <meta property="og:url" content="https://www.filmtv.site/" />
        <meta property="og:title" content="Film TV" />
        <meta property="og:description" content="Watch HD Movies and TV series online for free. Stream the latest releases in high quality without any cost." />
        <meta property="og:image" content={logo} />
        <meta property="twitter:card" content="summary" />
        <meta property="twitter:url" content="https://www.filmtv.site/" />
        <meta property="twitter:title" content="Film TV" />
        <meta property="twitter:description" content="Watch HD Movies and TV series online for free. Stream the latest releases in high quality without any cost." />
        <meta property="twitter:image" content={logo} />
      </Helmet>
      <div className='member-container'>
        <section id="membership-billing">
          <h2>FILM TV MEMBERSHIP</h2>
          <ul>
            <li><h6><b>Your Email:</b> {userEmail}</h6></li>
            <li><h6><b>User Id:</b> {userId}</h6></li>
            {/* Show the Reset Password option only if photoURL is empty */}
            {!userPhotoURL && (
              <li>
                <h6 className="lost-pass">
                  Lost your password?
                  <button onClick={() => setShowResetDialog(true)}>
                    Reset Here
                  </button>
                </h6>
              </li>
            )}
            <button className='signout-btn' onClick={signOut}>Signout</button>
          </ul>
        </section>
      </div>

      {showResetDialog && (
        <div className="reset-dialog-overlay">
          <div className="reset-dialog-content">
            <h2>Reset Password</h2>
            <p>Do you want to reset your password?</p>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <button onClick={handleResetPassword} disabled={loading}>
              {loading ? 'Sending...' : 'Yes'}
            </button>
            <button onClick={() => setShowResetDialog(false)}>No</button>
          </div>
        </div>
      )}

      <div className='mb-3'>
        {hasFavorites && (
          <div className="section mb-3">
            <div className="section__header mb-2">
              <h2>Wishlist</h2>
              <Link to="/favourites">
                {/* <OutlineButton className="small">View more</OutlineButton> */}
              </Link>
            </div>
            <FavouriteList />
          </div>
        )}
      </div>
    </div>
  );
}
