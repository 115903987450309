import React, { useState, useEffect } from "react";
import "./tv-banner.scss";
import { Link } from "react-router-dom";
import placeholderImage from "../../assets/placeholder.jpg";

const CartoonBanner = () => {
  const backgroundImageUrl = "https://images.alphacoders.com/709/70931.jpg";
  const posterImageUrl = "https://image.tmdb.org/t/p/original//mFvoEwSfLqbcWwFsDjQebn9bzFe.jpg";
  const title = "Explore the World of Animation";
  const fullDescription = "Dive into a universe of endless creativity, where imagination knows no bounds. Embark on adventures across fantastical worlds, meet unforgettable characters, and witness the power of storytelling through animation. From heartwarming tales to epic battles, each scene brings artistry and emotion to life, inviting audiences of all ages to dream, wonder, and believe in the extraordinary. Join us as we celebrate the boundless potential of animation, a medium that continues to inspire and captivate the world.";
  const linkPath = "genre/animation";

  const [description, setDescription] = useState(fullDescription);
  const [backgroundImage, setBackgroundImage] = useState(placeholderImage);
  const [posterImage, setPosterImage] = useState(placeholderImage);

  // Update description based on screen size
  useEffect(() => {
    const updateDescription = () => {
      if (window.innerWidth < 768) {
        setDescription(fullDescription.slice(0, 200) + "...");
      } else {
        setDescription(fullDescription);
      }
    };

    // Set initial description and listen for window resize
    updateDescription();
    window.addEventListener("resize", updateDescription);

    return () => window.removeEventListener("resize", updateDescription);
  }, [fullDescription]);

  useEffect(() => {
    const bgImage = new Image();
    bgImage.src = backgroundImageUrl;
    bgImage.onload = () => setBackgroundImage(backgroundImageUrl);
  }, [backgroundImageUrl]);

  useEffect(() => {
    const posterImg = new Image();
    posterImg.src = posterImageUrl;
    posterImg.onload = () => setPosterImage(posterImageUrl);
  }, [posterImageUrl]);

  return (
    <div
      className="banner-image"
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <div className="banner-content">
        <div className="details-container">
          <h1 className="title">{title}</h1>
          <p className="description">{description}</p>
          <Link to={linkPath} className="watch-now-button">
            Explore Now
          </Link>
        </div>
        <img
          src={posterImage}
          alt={title}
          className="poster"
        />
      </div>
    </div>
  );
};

export default CartoonBanner;
